import { Link } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Seo from '../../components/general/seo'
import Layout from '../../components/layout/layout'
import styles from './index.module.scss'
import { CardItemProps, ExpCard } from '@/components/top/exp-card'
import SectionTitle from '@/components/top/section-title'
import { thumbList } from '@/data/moc'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Video: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="Cognition Cafe Reserve" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1>
          <SectionTitle theme="dark">Cognition Cafe Reserve</SectionTitle>
        </h1>
        <p className={styles.titleSub}>
          &rdquo;Reserve&rdquo;は、認知機能に関するマエストロを目指してもらえるようにより専門的なコンテンツを紹介していきます。
          <br />
          はじめの特集は、精神科医を対象とした14の動画からなるACUMENです。
          <br />
          <span className={styles.updateInfo}>
            <strong>
              2月28日更新情報：
              <br />「
              <Link to="/video/4">
                第13回 双極I型障害における認知障害（ACUMEN#4）
              </Link>
              」
              <br className="u-pc-hidden" />「
              <Link to="/video/6">
                第14回 双極II型障害における認知機能（ACUMEN#6）
              </Link>
              」
            </strong>
          </span>
        </p>
        <div className={styles.intro}>
          <p>
            <strong>
              Advancing Cognition Understanding in MENtal illness (ACUMEN)
              <br />
            </strong>
            <strong>
              by the World Federation of Societies of Biological Psychiatry
              (WFSBP)
            </strong>
          </p>
          <p>
            <strong>プログラム・チェアー</strong>
            <br />
            Lakshmi N. Yatham 教授 (ブリティッシュコロンビア大学・カナダ)
            <br />
            Eduard Vieta 教授 (バルセロナ大学・スペイン)
          </p>
          <span className={styles.hrLine} />
          <div>
            <p>
              ACUMENとはWFSBPが作成した14モジュールからなる認知機能に関するe-learning
              プログラムで、EACCME®が認証した生涯教育用コンテンツです。このサイトでは、日本語字幕付きの動画をWFSBP
              の許可を得てそのまま配信します。
              <br />
              ACUMENの各動画は、Yatham
              教授およびVieta教授が、統合失調症および双極性障害の認知機能領域の世界的専門家にインタビューし、実臨床に役立つ最先端の内容が紹介されます。
              <br />
              なぜ精神疾患において認知機能に着目することが重要なのか、日常診療においてどのように認知機能と向き合えばよいのか、一緒に考えましょう。
              <br />
              なお、ACUMENの動画の掲載には、住友ファーマ株式会社からの資金提供を受けております。
            </p>
          </div>
        </div>
        <div className={styles.cardList}>
          {thumbList.map((item, index) => (
            <ExpCard key={index} cardItem={item as CardItemProps} />
          ))}
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'Cognition Cafe Reserve'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Video
